import type { ICurrency, IModule, IOption, IUser, UserNotification } from '@ts/interfaces/app.interfaces';
import type { IResponse } from '@ts/interfaces/api.interfaces';
import type { NumberOrNull, StringOrNull } from '@ts/types/app.type';
import { getGlobalData } from '@api/app';

interface State {
    loading: boolean;
    user: IUser | null;
    modules: IModule[];
    currencies: ICurrency[];
    accessSet: Record<string, any> | null;
    sessionId: StringOrNull;
    notifications: UserNotification[];
}

export const appStore = defineStore('app', {
    state: (): State => ({
        loading: true,
        user: null,
        modules: [],
        currencies: [],
        accessSet: null,
        sessionId: null,
        notifications: [],
    }),
    getters: {
        getUserInfo(): IUser | null {
            return this.user;
        },
        getModules(): IModule[] {
            return this.modules;
        },
        getCurrenciesOptions(): IOption[] {
            return this.currencies.map(({ id, abbreviation }) => ({ id, label: abbreviation }));
        },
    },
    actions: {
        setLoader(value: boolean): void {
            this.loading = value;
        },
        async getGlobalData(): Promise<IResponse> {
            this.setLoader(true);

            const { ok, message, data } = await getGlobalData();
            if (ok) {
                this.modules = data.modules;
                this.currencies = data.currencies;
                this.accessSet = data.accessSet;
                this.user = data.user;
                this.sessionId = data.sessionId || null;

                const notification = data.user?.notification;
                if (Array.isArray(notification)) {
                    this.notifications = notification;
                } else if (notification && typeof notification === 'object') {
                    this.notifications = [notification];
                }
            }

            this.setLoader(false);

            return { ok, message, data };
        },
        clearGlobalData(): void {
            this.setLoader(true);
            this.modules = [];
            this.currencies = [];
            this.accessSet = null;
            this.user = null;
            this.setLoader(false);
        },
        getCurrencyById(id: NumberOrNull): ICurrency | null {
            return this.currencies.find((c: ICurrency): boolean => c.id === id) || null;
        },
        getCurrencyByExternalId(externalId: string): ICurrency | null {
            return this.currencies.find((c: ICurrency): boolean => c.externalId === externalId) || null;
        },
        removeUserNotification(id: number): boolean {
            const index: number = this.notifications.findIndex((n: UserNotification) => n.id === id);

            if (index > -1) {
                this.notifications.splice(index, 1);
                return true;
            }

            return false;
        },
    },
});
