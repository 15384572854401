import type { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import axios from '@/api.instance';

interface Queue {
    promise: () => any;
    resolve: (value: AxiosResponse) => void;
    reject: (error: AxiosError) => void;
}

class Api {
    currentQueue: Queue[] = [];
    pendingPromise: boolean = false;
    http: AxiosInstance = axios;
    retryTimeout: number | undefined = undefined;

    queue(promise: any): Promise<AxiosResponse> {
        return new Promise((resolve, reject): void => {
            this.currentQueue.push({
                promise,
                resolve,
                reject,
            });
            this.dequeue();
        });
    }

    retry(): void {
        clearTimeout(this.retryTimeout);
        console.warn('Trying to connect to the internet!');
        this.retryTimeout = setTimeout(() => this.dequeue(), 5000) as unknown as number;
    }

    afterResolvePromise = (item: Queue, value: AxiosResponse): void => {
        this.pendingPromise = false;
        item.resolve(value);
        this.dequeue();
    };

    afterRejectPromise = (item: Queue, error: AxiosError | any): void => {
        this.pendingPromise = false;
        item.reject(error);
        this.dequeue();
    };

    dequeue(): boolean {
        if (this.pendingPromise) {
            return false;
        }

        if (window.navigator && !window.navigator.onLine) {
            this.retry();
            return false;
        }

        const item: Queue | undefined = this.currentQueue.shift();
        if (!item) {
            return false;
        }

        try {
            this.pendingPromise = true;
            item.promise()
                .then((value: AxiosResponse) => this.afterResolvePromise(item, value))
                .catch((error: AxiosError) => this.afterRejectPromise(item, error));
        } catch (error) {
            this.afterRejectPromise(item, error);
        }

        return true;
    }

    debug() {
        return new Promise(res => setTimeout(() => res('Completed'), 2000));
    }

    async request(options: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.http(options)
            .then((response: AxiosResponse) => response)
            .catch((error: AxiosError) => Promise.reject(error));
    }
}

const api: Api = new Api();

const queuedApi = {
    currentQueue: api.currentQueue,
    debug() {
        return api.queue(() => api.debug());
    },
    async get(url: string = '', options = {}): Promise<AxiosResponse> {
        return api.queue(() => api.request({ method: 'get', url, ...options }));
    },
    async post(url: string = '', options = {}): Promise<AxiosResponse> {
        return api.queue(() => api.request({ method: 'post', url, ...options }));
    },
    async put(url: string = '', options = {}): Promise<AxiosResponse> {
        return api.queue(() => api.request({ method: 'put', url, ...options }));
    },
    async delete(url: string = '', options = {}): Promise<AxiosResponse> {
        return api.queue(() => api.request({ method: 'delete', url, ...options }));
    },
};

export default queuedApi;
