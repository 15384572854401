<template>
    <header class="w-full z-50 shadow-header bg-white" :class="{ fixed: fixedHeader }">
        <div class="grid grid-cols-12 gap-6 container">
            <div class="logo flex items-center">
                <Logo :fixed="fixedHeader" />
            </div>
            <div class="col-span-11">
                <div
                    class="flex justify-between items-center py-3"
                    :class="{ hidden: fixedHeader, 'h-full': !modules?.length }"
                >
                    <div class="flex gap-8">
                        <a
                            href="https://www.lbr.ru/"
                            target="_blank"
                            class="text__link flex items-center gap-2 link_icon"
                        >
                            {{ $t('Back to the website for Buyers') }}
                        </a>

                        <LanguageSwitcher />
                    </div>

                    <div class="flex items-center">
                        <Notification hidden />
                        <Login v-if="!token" />
                        <div v-else class="gap-4 flex">
                            <UserInfo v-if="userInfo" :email="userInfo.email" :company-name="userInfo.companyName" />
                            <Logout />
                        </div>
                    </div>
                </div>
                <div v-if="modules.length" class="border-t border-t-primary-lighter main-menu">
                    <Menu :menu-items="modules" class="flex justify-content-between flex-nowrap gap-4" />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
import type { IModule, IUser } from '@ts/interfaces/app.interfaces';
import type { StringOrNull } from '@ts/types/app.type';
import { appStore } from '@s/app';
import { authStore } from '@s/auth';
import LanguageSwitcher from '@l/default/components/Header/components/LanguageSwitcher.vue';
import Logo from './components/Logo.vue';
import Notification from './components/Notification.vue';
import Login from './components/Login.vue';
import Logout from './components/Logout.vue';
import UserInfo from './components/UserInfo.vue';
import Menu from './components/Menu.vue';

/** Data **/
const modules = appStore().getModules as IModule[];
const userInfo = appStore().getUserInfo as IUser | null;
const fixedHeader = ref<boolean>(false);

/** Computed **/
const token = computed<StringOrNull>(() => authStore().getToken);

/** Mounted **/
onMounted(() => {
    if (process.client) {
        const header: HTMLElement | null = document.querySelector('header');
        const main: HTMLElement | null = document.querySelector('main');

        if (header && main) {
            const headerOffsetHeight: number = header.offsetHeight || 119;

            fixedHeader.value = window.scrollY > headerOffsetHeight;
            (main.style as CSSStyleDeclaration).marginTop = toValue(fixedHeader) ? `${headerOffsetHeight}px` : '0';

            document.onscroll = () => {
                fixedHeader.value = window.scrollY > headerOffsetHeight;
                (main.style as CSSStyleDeclaration).marginTop = toValue(fixedHeader) ? `${headerOffsetHeight}px` : '0';
            };
        }
    }
});
</script>

<style scoped lang="scss">
@use "assets/styles/variables" as *;

.link_icon {
    &:after {
        font-family: 'icomoon', serif !important;
        content: '\e90b';
        font-size: $size-16;
        line-height: 1;
    }
}
</style>
