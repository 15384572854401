<template>
    <div class="cursor-pointer flex items-center" @click="logout">
        <span class="icon-logout" />
    </div>
</template>

<script setup lang="ts">
import { authStore } from '@s/auth';

/** Methods **/
const logout = (): void => {
    authStore().removeToken();
    authStore().removeRefreshToken();
};
</script>
