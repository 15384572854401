import { PagesNamesList } from '@ts/enums/app.enum';
import type { StringOrNull } from '@ts/types/app.type';
import type { LocationQueryRaw, RouteLocationNormalizedLoadedGeneric } from 'vue-router';
import axios, { type AxiosInstance } from 'axios';
import { authStore } from '@s/auth';
import { appStore } from '@s/app';

const api: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL + import.meta.env.VITE_API_PATH,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
    },
});

api.interceptors.request.use(
    async config => {
        if ((config.url as string).includes('auth/')) {
            return config;
        }

        const store = authStore();
        const token: StringOrNull = await store.checkAuthAndGetToken();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => error?.request || null,
);

api.interceptors.response.use(
    response => response,
    async error => {
        const status = error?.response?.status;
        switch (status) {
            case 401: {
                appStore().clearGlobalData();
                authStore().removeToken();
                authStore().removeRefreshToken();

                const route: RouteLocationNormalizedLoadedGeneric = useRoute();
                const query: LocationQueryRaw = {};
                if (![PagesNamesList.HOME, PagesNamesList.AUTH].includes(route.name as PagesNamesList)) {
                    query.redirect = route.fullPath;
                }
                await navigateTo({ path: '/auth', query });

                return {
                    ...error.response,
                    data: {
                        ok: false,
                        message: 'Not authorized',
                        data: null,
                    },
                };
            }
            case 403: {
                return {
                    ...error.response,
                    data: {
                        ok: false,
                        message: 'No access',
                        data: null,
                    },
                };
            }
            default: {
                return {
                    ...(error?.response || {}),
                    data: {
                        ok: false,
                        message: 'Unforeseen error',
                        data: null,
                    },
                };
            }
        }
    },
);

export default api;
