<template>
    <Loader v-if="appStore().loading" />

    <div v-else class="h-full flex flex-col">
        <Header />

        <main class="flex-1">
            <div class="container py-8 h-full">
                <div class="gap-4 flex flex-col items-center h-full justify-center">
                    <p class="h1">404</p>

                    <p class="h2">{{ $t('Page not found') }}</p>

                    <Button
                        class="mt-4"
                        :value="$t('Go to main page')"
                        :size="ESize.LARGE"
                        @click="navigateTo({ path: '/' })"
                    />
                </div>
            </div>
        </main>

        <Footer />
    </div>
</template>

<script setup lang="ts">
import { ESize } from '@ts/enums/app.enum';
import { appStore } from '@s/app';
import Header from '@l/default/components/Header/index.vue';
import Footer from '@l/default/components/Footer/index.vue';
import Loader from '@c/Loader.vue';
import Button from '@c/Button.vue';

/** Mounted **/
onMounted(async (): Promise<void> => {
    await appStore().getGlobalData();
});
</script>

<style scoped lang="scss">
@use "assets/styles/variables" as *;

.h1 {
    font-size: 160px;
    line-height: 1;
    color: $color-secondary-dark;
    font-weight: 600;
    user-select: none;
}

.h2 {
    font-size: 48px;
    line-height: 1;
    user-select: none;
}
</style>
