<template>
    <div>
        <Select
            v-model="locale"
            :class="{ open: isOpen }"
            :options="languageList"
            option-label="name"
            option-value="id"
            :unstyled="true"
            :pt="ptSelect"
            @update:modelValue="update"
            @before-show="isOpen = true"
            @before-hide="isOpen = false"
        >
            <template #value="{ value }">
                <span class="flex align-center gap-2">
                    <img class="select-language__img" :src="getIcon(value)" :alt="value" />
                    <span class="select-language__desc">{{ getSlug(value) }}</span>
                </span>
            </template>

            <template #option="{ option }">
                <span class="flex align-center gap-2">
                    <img class="select-language__img" :src="getIcon(option.id)" :alt="option.id" />
                    <span class="select-language__desc">{{ getLabel(option.id) }}</span>
                </span>
            </template>

            <template #dropdownicon>
                <span class="select-language__icon icon-chevron-down icon_small" />
            </template>
        </Select>
    </div>
</template>

<script setup lang="ts">
import { ELanguage } from '@ts/enums/app.enum';
import type { ILanguage } from '@ts/interfaces/app.interfaces';
import LocalStorage from '@h/local-storage';
import Select from 'primevue/select';

/** Data **/
const { locale, setLocale } = useI18n();
const languages: ILanguage[] = useAppConfig().languages;
const isOpen = ref<boolean>(false);
const ptSelect = readonly({
    root: 'select-language',
    label: 'select-language__label',
    overlay: 'select-language__overlay',
    list: 'select-language__list',
    option: 'select-language__option',
});

/** Computed **/
const languageList = computed<ILanguage[]>(() => languages.filter((l: ILanguage) => l.id !== toValue(locale)));

/** Methods **/
const getIcon: (id: ELanguage) => string = (id: ELanguage): string => {
    const assets: Record<string, string> = import.meta.glob('~/assets/icons/flags/*', {
        eager: true,
        import: 'default',
    });

    return assets[`/assets/icons/flags/${id}.svg`];
};

const getLabel = (id: ELanguage): string => languages.find((i: ILanguage) => i.id === id)?.name || '';

const getSlug = (id: ELanguage): string => languages.find((i: ILanguage) => i.id === id)?.slug || '';

const update = (value: string): void => {
    LocalStorage.set('locale', value);
    setLocale(value);
};
</script>

<style lang="scss">
@use "assets/styles/variables" as *;

.select-language {
    display: flex;
    align-items: center;
    column-gap: 4px;
    user-select: none;
    cursor: pointer;
    padding: 4px 8px;
    width: 100px;
    background-color: $color-white;

    &.open {
        background-color: $color-primary-lighter-2;

        .select-language__icon {
            transform: rotate(180deg);
        }
    }

    &__icon {
        display: flex;
        transition: $transition;

        &:before {
            color: #6c6c6c;
        }
    }

    &__img {
        width: 18px;
        height: 16px;
    }

    &__desc {
        color: $color-black;
        font-size: $size-13;
        font-weight: $weight-500;
        line-height: 16px;
    }

    &__overlay {
        background-color: $color-primary-lighter-2;
        padding: 8px;
        margin-top: 0 !important;

        .p-hidden-accessible {
            display: none;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }

    &__option {
        cursor: pointer;
    }
}
</style>
