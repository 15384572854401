import type { IBanner, ILanguage, ISocial } from '@ts/interfaces/app.interfaces';
import { ELanguage } from '@ts/enums/app.enum';

export default defineAppConfig({
    socials: [
        {
            type: 'tg',
            link: 'https://t.me/lbr_ru/',
        },
        {
            type: 'tiktok',
            link: 'https://www.tiktok.com/@lbr.ru/',
        },
        {
            type: 'vk',
            link: 'https://vk.com/lbragro',
        },
        {
            type: 'youtube',
            link: 'https://www.youtube.com/user/LBRAgromarket/',
        },
        {
            type: 'ok',
            link: 'https://ok.ru/group/53776660365514',
        },
    ] as ISocial[],
    languages: [
        {
            id: ELanguage.RU,
            slug: 'rus',
            name: 'Русский',
        },
        {
            id: ELanguage.EN,
            slug: 'eng',
            name: 'English',
        },
    ] as ILanguage[],
    noRouteLink: '/in-developing' as string,
    banners: {
        requests: [
            {
                type: 'green',
                text: 'Просим отвечать на запросы быстро и давать реальные сроки',
                pin: 'red',
                image: 'banner1',
            },
            {
                type: 'blue',
                text: "По-возможности, в первую очередь отрабатывать сервисные запросы",
                pin: 'yellow',
                image: 'banner2',
            },
            {
                type: 'purple',
                text: 'Просим соблюдать наши договоренности и предоставлять корректную информацию',
                pin: 'green',
                image: 'banner3',
            },
            {
                type: 'light-green',
                text: 'При необходимости запрашивать у нас информацию о работе с ПП и итоги работы по нему',
                pin: 'blue',
                image: 'banner4',
            }
        ],
        orders: [
            {
                type: 'green',
                text: 'Просим обрабатывать заказы быстро и давать сроки и цены, соответствующие шорт-листам',
                pin: 'red',
                image: 'banner5',
            },
            {
                type: 'blue',
                text: "По-возможности, в первую очередь отрабатывать сервисные заказы",
                pin: 'yellow',
                image: 'banner2',
            },
            {
                type: 'purple',
                text: 'Просим соблюдать наши договоренности и предоставлять корректные документы в срок',
                pin: 'green',
                image: 'banner3',
            },
            {
                type: 'light-green',
                text: 'При необходимости запрашивать у нас информацию о работе с ПП и итоги работы по нему',
                pin: 'blue',
                image: 'banner4',
            }
        ],
    } as Record<string, IBanner[]>,
})
