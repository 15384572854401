import { ELanguage } from '@ts/enums/app.enum';
import moment from 'moment';
import 'moment/dist/locale/ru';
import { getStartingLocale } from '@h/locales';

moment.locale(getStartingLocale());
moment.updateLocale(ELanguage.RU, {
    longDateFormat: {
        LT: 'H:mm',
        LTS: 'H:mm:ss',
        l: 'D MMMM YYYY',
        L: 'DD.MM.YYYY',
        LL: 'DD.MM.YYYY, H:mm',
        LLL: 'D MMMM YYYY г., H:mm:ss',
        LLLL: 'dddd, D MMMM YYYY г., H:mm:ss',
    },
});
moment.updateLocale(ELanguage.EN, {
    longDateFormat: {
        LT: 'H:mm',
        LTS: 'H:mm:ss',
        l: 'D MMMM YYYY',
        L: 'DD.MM.YYYY',
        LL: 'DD.MM.YYYY, H:mm',
        LLL: 'D MMMM YYYY г., H:mm:ss',
        LLLL: 'dddd, D MMMM YYYY г., H:mm:ss',
    },
});

export default defineNuxtPlugin(() => ({
    provide: {
        moment,
    },
}));
