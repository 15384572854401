import VueGtag, { trackRouter } from 'vue-gtag-next';

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.use(VueGtag, {
        property: {
            id: 'GTM-NN9XTFW2',
        },
    });
    trackRouter(useRouter());
});
