<template>
    <a v-if="link && imageUrl" :href="link" target="_blank">
        <img :src="imageUrl" :alt="type" />
    </a>
</template>

<script setup lang="ts">
/** Interfaces **/
interface Props {
    type: string;
    link: string;
}

/** Props **/
const props = defineProps<Props>();

/** Data **/
const imageUrl = computed<string>(() => {
    const assets: Record<string, string> = import.meta.glob('~/assets/icons/social/*', {
        eager: true,
        import: 'default',
    });

    return assets[`/assets/icons/social/${props.type}.svg`];
});
</script>
