import type { StringOrNull } from '@ts/types/app.type';
import { PagesNamesList } from '@ts/enums/app.enum';
import type { RouteLocationNormalizedLoadedGeneric, LocationQueryRaw } from 'vue-router';
import { authStore } from '@s/auth';

export default defineNuxtRouteMiddleware(
    async (to: RouteLocationNormalizedLoadedGeneric, from: RouteLocationNormalizedLoadedGeneric) => {
        /** Redirect **/
        // Parts requests
        if (to.path === '/purchasing-logistics/spareparts-requests') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/requests-parts',
                    query: to.query,
                },
                { redirectCode: 301, external: true },
            );
        }

        if (to.path === '/purchasing-logistics/spareparts-requests/archive') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/requests-parts',
                    query: { is_archived: 1, ...to.query },
                },
                { redirectCode: 301, external: true },
            );
        }

        // Techs requests
        if (to.path === '/purchasing-logistics/tech-requests') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/requests-techs',
                    query: to.query,
                },
                { redirectCode: 301, external: true },
            );
        }

        if (to.path === '/purchasing-logistics/tech-requests/archive') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/requests-techs',
                    query: { is_archived: 1, ...to.query },
                },
                { redirectCode: 301, external: true },
            );
        }

        // Orders
        if (to.path === '/purchasing-logistics/spareparts-orders-for-confirmation') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/orders-parts',
                    query: to.query,
                },
                { redirectCode: 301, external: true },
            );
        }

        if (to.path === '/purchasing-logistics/spareparts-orders-for-confirmation/archive') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/orders-parts',
                    query: { is_archived: 1, ...to.query },
                },
                { redirectCode: 301, external: true },
            );
        }

        // Orders shipping
        if (to.path === '/purchasing-logistics/spareparts-orders-for-shipment') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/orders-parts/shipping',
                    query: { status_id: 6, ...to.query },
                },
                { redirectCode: 301, external: true },
            );
        }

        if (to.path === '/purchasing-logistics/spareparts-orders-for-shipment/archive') {
            return await navigateTo(
                {
                    path: '/purchasing-logistics/orders-parts/shipping',
                    query: { status_id: 6, is_archived: 1, ...to.query },
                },
                { redirectCode: 301, external: true },
            );
        }

        // Если мы переходим на страницу автологина
        if (to.name === PagesNamesList.AUTO_LOGIN) return;

        const token: StringOrNull = await authStore().checkAuthAndGetToken();

        // Если пытаемся попасть на страницу авторизации и мы авторизованы
        if (token && to.name === PagesNamesList.AUTH) {
            return await navigateTo(
                from.name === PagesNamesList.AUTH ? (to?.query?.redirect as string | undefined) || '/' : from.fullPath,
            );
        }

        if (!token && to.name !== PagesNamesList.AUTH) {
            const query: LocationQueryRaw = {};
            if (to.path !== '/') {
                query.redirect = to.path;
            }
            return await navigateTo({ path: '/auth', query });
        }
    },
);
