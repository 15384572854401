import type { ITokenForm } from '@ts/interfaces/app.interfaces';
import type { IResponse } from '@ts/interfaces/api.interfaces';
import type { StringOrNull } from '@ts/types/app.type';
import api from '@/api.queue.instance';

const getToken = async (authFields: ITokenForm): Promise<IResponse> =>
    (await api.post('auth/token', { data: authFields })).data;

const getRefreshToken = async (token: StringOrNull): Promise<IResponse> =>
    (await api.post('auth/refresh-token', { data: { token } })).data;

const replaceQsiToJwt = async (qsiToken: string): Promise<IResponse> =>
    (await api.post('auth/token-qsi', { data: { qsiToken } })).data;

export default { getToken, getRefreshToken, replaceQsiToJwt };
