<template>
    <div>
        <span class="cursor-pointer" @click.stop="copyEvent">
            <span>{{ value || model }}</span>
            <span v-if="icon" class="icon-copy icon_extra-small ml-1" />
        </span>

        <slot />
    </div>
</template>

<script setup lang="ts">
import type { StringOrNull } from '@ts/types/app.type';

/** Interfaces **/
interface Props {
    model: string;
    value?: StringOrNull;
    message?: string;
    icon?: boolean;
}

/** Props **/
const props = withDefaults(defineProps<Props>(), {
    value: null,
    icon: false,
});
const { model, message } = toRefs(props);

/** Data **/
const toast = useToast();

/** Methods **/
const copyEvent = (): void => {
    navigator.clipboard.writeText(toValue(model));

    if (toValue(message)) {
        toast.add({
            severity: 'success',
            summary: toValue(message),
            group: 'notification',
            life: 3000,
        });
    }
};
</script>
