<template>
    <footer class="bg-wrap-footer">
        <div class="container flex justify-between items-center py-3">
            <NuxtLink :to="{ name: PagesNamesList.HOME }">
                <img :src="getMainLogo" :alt="siteTitle" :title="siteTitle" />
            </NuxtLink>

            <div class="flex gap-3">
                <span class="text__content color-dark !font-semibold">{{ $t('We are in social media') }}:</span>
                <div class="flex flex-nowrap gap-2">
                    <SocialIcon v-for="social in socials" :key="social.type" :type="social.type" :link="social.link" />
                </div>
            </div>
        </div>
        <div v-if="appStore().sessionId" class="container py-2 session-id">
            <CopyText :model="appStore().sessionId || ''" />
        </div>
    </footer>
</template>

<script setup lang="ts">
import { ELanguage, PagesNamesList } from '@ts/enums/app.enum';
import type { ISocial } from '@ts/interfaces/app.interfaces';
import { appStore } from '@s/app';
import SocialIcon from './components/SocialIcon.vue';
import CopyText from '@c/CopyText.vue';

/** Data **/
const { locale } = useI18n();
const socials: ISocial[] = useAppConfig().socials;
const siteTitle: string = useRuntimeConfig().public.projectName as string || '';

/** Computed **/
const getMainLogo = computed<string>(() => {
    const assets: Record<string, string> = import.meta.glob('~/assets/icons/*', {
        eager: true,
        import: 'default',
    });

    return assets[
        toValue(locale) === ELanguage.RU
            ? '/assets/icons/logo-with-right-title.svg'
            : `/assets/icons/logo-with-right-title--${toValue(locale)}.svg`
    ];
});

/** Mounted **/
onMounted(() => {
    document.onkeydown = event => {
        if (event.ctrlKey && event.shiftKey && event.altKey && event.code === 'Backquote') {
            if (appStore().sessionId) {
                navigator.clipboard.writeText(appStore().sessionId as unknown as string);
            }
        }
    };
});
</script>

<style scoped lang="scss">
@use "assets/styles/variables" as *;

.session-id {
    font-size: $size-12;
    font-weight: $weight-500;
    line-height: $line-height-140;
    color: $color-natural-default;
}
</style>
