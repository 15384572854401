<template>
    <nav>
        <div v-for="module in menuItems" :key="module.id" class="menu-item-wrapper">
            <MenuItem
                :title="module.title"
                :slug="createLink(module)"
                :is-hidden="module.isHidden"
                :notes-count="0"
                :is-parent="!parent"
                class="flex flex-row text-center text"
                :class="{ 'router-link-active': activeParentLink(module) }"
            />

            <Menu
                v-if="module?.children?.length && !module.isHidden"
                :parent="module"
                :menu-items="module.children"
                class="sub-menu p-3"
            />
        </div>
    </nav>
</template>

<script setup lang="ts">
import type { IModule } from '@ts/interfaces/app.interfaces';
import type { StringOrNull } from '@ts/types/app.type';
import MenuItem from '@l/default/components/Header/components/MenuItem.vue';

/** Interfaces **/
interface Props {
    menuItems: IModule[];
    parent?: IModule;
}

/** Props **/
const props = withDefaults(defineProps<Props>(), {
    menuItems: () => [],
});

const { parent } = toRefs(props);

/** Methods **/
const createLink = (module: IModule): StringOrNull => {
    if (module.isHidden) {
        return null;
    }

    return '/' + [toValue(parent)?.slug || '', module.slug].filter(i => i).join('/');
};

const activeParentLink = (module: IModule): boolean => {
    if (toValue(parent)?.slug || (!module.slug && !module?.children?.length)) {
        return false;
    }

    const currentPathArr: string[] = useRoute().fullPath.slice(1).split('/');
    return module.slug === currentPathArr[0];
};
</script>

<style scoped lang="scss">
@use "assets/styles/variables" as *;

.menu-item-wrapper {
    position: relative;

    .sub-menu {
        min-width: 180px;
        display: none;
        background: white;
        position: absolute;
        left: -12px;
        box-shadow: 0 0 9px -5px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0 0 9px -5px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0 0 9px -5px rgba(0, 0, 0, 0.75);

        a {
            padding: 4px;
            text-align: left;
            color: $color-natural-darker;

            &.router-link-active {
                color: $color-secondary-default;
            }

            &:hover {
                color: $color-secondary-dark;
            }

            &:active {
                color: $color-focus-dark;
                position: relative;
            }

            &[disabled] {
                display: none;
            }
        }
    }

    &:hover {
        a {
            color: $color-primary-default;

            &:after {
                content: '';
                background-color: $color-secondary-default;
            }
        }

        .sub-menu {
            display: block;

            a {
                &:after {
                    content: none;
                }

                &.disabled {
                    color: grey;
                }
            }
        }
    }
}
</style>
