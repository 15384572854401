import type { StringOrNull } from '@ts/types/app.type';
import { decoder, encoder } from '@h/coder';

const get = (name: string, isDecoder: boolean = false): any => {
    const item: StringOrNull = localStorage.getItem(name);
    if (!item) {
        return null;
    }

    try {
        return JSON.parse(isDecoder ? decoder(item) : item);
    } catch (e) {
        return isDecoder ? decoder(item) : item;
    }
};

const set = (name: string, value: any, isEncoder: boolean = false): void => {
    value = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(name, isEncoder ? encoder(value) : value);
};

const remove = (name: string): void => {
    localStorage.removeItem(name);
};

export default { get, set, remove };
