<template>
    <Loader v-if="appStore().loading" />

    <NuxtLayout v-else>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup lang="ts">
import type { LocationQueryRaw } from 'vue-router';
import type { IToken } from '@ts/interfaces/app.interfaces';
import { PagesNamesList } from '@ts/enums/app.enum';
import type { StringOrNull } from '@ts/types/app.type';
import { appStore } from '@s/app';
import { authStore } from '@s/auth';
import Loader from '@c/Loader.vue';

/** Watch **/
watch(
    () => authStore().token,
    async (newValue: IToken | null, oldValue: IToken | null) => {
        if (oldValue?.token === newValue?.token) return;

        setTimeout(() => (appStore().setLoader(true)), 0);
        await handler(newValue?.token || null);
        setTimeout(() => (appStore().setLoader(false)), 0);
    },
    { deep: true },
);

/** Mounted **/
onMounted(async () => {
    if (useRoute().name !== PagesNamesList.AUTO_LOGIN) {
        await handler(await authStore().checkAuthAndGetToken());
    } else {
        setTimeout(() => (appStore().setLoader(false)), 0);
    }
});

/** Methods **/
const handler = async (state: StringOrNull): Promise<void> => {
    if (!state) {
        appStore().clearGlobalData();
        authStore().removeToken();
        authStore().removeRefreshToken();

        let query: LocationQueryRaw = {};
        // Если Главная страница, то редирект нам не нужен
        if (useRoute().name !== PagesNamesList.HOME) {
            // Если страница авторизации, то оставляем get параметры
            if (useRoute().name === PagesNamesList.AUTH) {
                query = { ...useRoute().query };
            } else {
                // В ином случае добавляем get параметр redirect на страницу, с которой мы перешли на auth page
                query.redirect = useRoute().path;
            }
        }
        await navigateTo({ path: '/auth', query });

        setTimeout(() => (appStore().setLoader(false)), 0);
    } else {
        await appStore().getGlobalData();
    }
};
</script>
