// https://router.vuejs.org/api/interfaces/routeroptions.html
import type { RouterConfig } from '@nuxt/schema';
import type { RouteParams, RouteLocationNormalizedLoadedGeneric } from 'vue-router';
import { PagesNamesList, EProductType } from '@ts/enums/app.enum';

export default <RouterConfig>{
    routes: () => [
        {
            name: PagesNamesList.IN_DEVELOPING,
            path: '/in-developing',
            component: () => import('~/pages/NoAccess/index.vue').then(r => r.default || r),
        },
        {
            name: PagesNamesList.HOME,
            path: '/',
            component: () => import('~/pages/Home/index.vue').then(r => r.default || r),
        },
        {
            name: PagesNamesList.AUTH,
            path: '/auth',
            component: () => import('~/pages/Auth/index.vue').then(r => r.default || r),
        },
        {
            name: PagesNamesList.AUTO_LOGIN,
            path: '/site/login',
            component: () => import('~/pages/Auth/AutoLogin.vue').then(r => r.default || r),
        },
        {
            name: PagesNamesList.PURCHASING_LOGISTICS_REQUESTS,
            path: '/purchasing-logistics/requests-:type',
            component: () => import('~/pages/PurchasingLogistics/Requests/index.vue').then(r => r.default || r),
            beforeEnter: (to: RouteLocationNormalizedLoadedGeneric) =>
                [EProductType.PARTS, EProductType.TECHS].includes(
                    (to.params as RouteParams)?.type as EProductType,
                ),
        },
        {
            name: PagesNamesList.PURCHASING_LOGISTICS_ORDERS,
            path: '/purchasing-logistics/orders-:type',
            component: () => import('~/pages/PurchasingLogistics/Orders/index.vue').then(r => r.default || r),
            beforeEnter: (to: RouteLocationNormalizedLoadedGeneric) =>
                [EProductType.PARTS, EProductType.TECHS].includes(
                    (to.params as RouteParams)?.type as EProductType,
                ),
        },
        {
            name: PagesNamesList.PURCHASING_LOGISTICS_ORDERS_SHIPPING,
            path: '/purchasing-logistics/orders-:type/shipping',
            component: () => import('~/pages/PurchasingLogistics/Orders/Shipping.vue').then(r => r.default || r),
            beforeEnter: (to: RouteLocationNormalizedLoadedGeneric) =>
                [EProductType.PARTS, EProductType.TECHS].includes(
                    (to.params as RouteParams)?.type as EProductType,
                ),
        },
        {
            name: PagesNamesList.FINANCING_PAYMENTS,
            path: '/financing/payments',
            component: () => import('~/pages/Financing/Payments/index.vue').then(r => r.default || r),
        },
        {
            path: '/commercial-agreements',
            component: () => import('~/pages/CommercialAgreements/layout.vue').then(r => r.default || r),
            children: [
                {
                    name: PagesNamesList.COMMERCIAL_AGREEMENTS,
                    path: '',
                    component: () => import('~/pages/CommercialAgreements/index.vue').then(r => r.default || r),
                },
                {
                    name: PagesNamesList.COMMERCIAL_AGREEMENTS_TYPE,
                    path: ':type',
                    component: () => import('~/pages/CommercialAgreements/type.vue').then(r => r.default || r),
                },
                {
                    name: PagesNamesList.COMMERCIAL_AGREEMENTS_ITEM,
                    path: ':type/:id',
                    component: () => import('~/pages/CommercialAgreements/id.vue').then(r => r.default || r),
                },
            ],
        },
    ],
};
