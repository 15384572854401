<template>
    <NuxtLink :to="{ name: PagesNamesList.HOME }" class="flex items-center">
        <img v-if="fixed" src="@a/icons/logo-without-title.svg" :alt="siteTitle" :title="siteTitle" />
        <img v-else :src="getMainLogo" :alt="siteTitle" :title="siteTitle" />
    </NuxtLink>
</template>

<script setup lang="ts">
import { ELanguage, PagesNamesList } from '@ts/enums/app.enum';

/** Interfaces **/
interface Props {
    fixed?: boolean;
}

/** Props **/
withDefaults(defineProps<Props>(), {
    fixed: false,
});

/** Data **/
const { locale } = useI18n();
const siteTitle: string = useRuntimeConfig().public.projectName as string || '';

/** Computed **/
const getMainLogo = computed<string>(() => {
    const assets: Record<string, string> = import.meta.glob('~/assets/icons/*', {
        eager: true,
        import: 'default',
    });

    return assets[
        toValue(locale) === ELanguage.RU
            ? '/assets/icons/logo-with-title.svg'
            : `/assets/icons/logo-with-title--${toValue(locale)}.svg`
    ];
});
</script>
