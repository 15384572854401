
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as AutoLoginKLpzOtCLp0UuwopmG0QHQixv2kFJ5RY_45FiNJc32k6cMMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Auth/AutoLogin.vue?macro=true";
import { default as indexFom5oXzNgiFdKATv9J9UVYf_0S8cYz7G5hoqoAqTRSgMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Auth/index.vue?macro=true";
import { default as idxocpp42n2i7oJUqc3YMJgUNoFxE_456NMmx5put4sswKQMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/FinancingConditions/id.vue?macro=true";
import { default as indexuBkOMW5ojZiZlFrHs4J7Q8utqpcWSbmABGjOTu9xXFwMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/FinancingConditions/index.vue?macro=true";
import { default as Menu1czeuidmwzsS04Uc4xN8visfO4J5JyDWCixxRE0_HF8Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/Menu.vue?macro=true";
import { default as FilteryaEYjqi3abfixC_45x8zUXxYLfMXEOTOJa1uyUImbD8WIMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/NegotiationProtocol/components/Filter.vue?macro=true";
import { default as ItemewsiejQJe7Wq8lapMsf0cs6ekm7Ih1POu_36CidkjigMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/NegotiationProtocol/components/Item.vue?macro=true";
import { default as indexYMeUuGom4W6ee3_TrJDJI5O5DimFfnaJeFCq_6tqDK0Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/NegotiationProtocol/index.vue?macro=true";
import { default as FilterDqz1Hk_45ATNDRlvm9DpZFu8pa43KpSXI6tcsje_45qk_e8Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/PaymentTerms/components/Filter.vue?macro=true";
import { default as Item0yIyv9HC3ltgBr_8afUI0M11jaUBu3sinXCHMR40EJIMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/PaymentTerms/components/Item.vue?macro=true";
import { default as indexxMgEIpPSlbeht5y_tSt1U10OoedBLGpMm7TMt_lCjz4Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/PaymentTerms/index.vue?macro=true";
import { default as ExcelXfyZvm9H4XD1AfpnamXWPBbffJJgJP4USrGV8KzFiQoMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/Excel.vue?macro=true";
import { default as FilterLxJHNQdj9xMzqgrgFZwN4kQmKHSA3yzrCWFVkiYxD3wMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/Filter.vue?macro=true";
import { default as ItemsTableQrix0RRHmQoDNAPLf9NbzHvwIa_45pNF9klOn8M8V3OgwMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/ItemsTable.vue?macro=true";
import { default as TypeItemULF8YbHq6obTL_45cHy_45CpEgg31OLc7RLmjto9dkK_45zuIMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/TypeItem.vue?macro=true";
import { default as idWyKNo_60bzYBpgGy12xkC6t99VHY9VrgNd3_45oUQLQpkMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/id.vue?macro=true";
import { default as indexH38fY3awbDxI1RnlvgG5YL_45wJCTnbfBcnsmxbb_45_XSAMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/index.vue?macro=true";
import { default as id6_455G_45WWooF33BmiiUjZbLTDJC0Qj53U8NUAxTANBgxMMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/id.vue?macro=true";
import { default as indexH910P8scMPgqs9UdzV_2c5qQb5W0sqtawX7CSmVcYvoMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/index.vue?macro=true";
import { default as layoutS4Osb1Fgnf1bal8SMkWGbPQtrh2n5LUDu1lwVlrXbLcMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/layout.vue?macro=true";
import { default as type_450BnzKjcR0Zrh2QYagm1STpgqvTi5ZZ5vE6dkUzS3WEMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/type.vue?macro=true";
import { default as index9IFufhiWjia3bPLEWoi92V4i4W2toYjf04jXNM9oHjcMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Excel/index.vue?macro=true";
import { default as Modal60PaNfHzrXrIXfPGFagJ6LIyGZJs5wBZHedDdz6CIXoMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Excel/Modal.vue?macro=true";
import { default as AmountoJ17VSvW3FtEhGuyD2TbSbeOa817nOAXSZ76wFM3lTIMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Fields/Amount.vue?macro=true";
import { default as indexaMJTIh_cdbPBdJq1RM3Sn1OYrsm3c8DRNaZmvvaEsj4Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Fields/index.vue?macro=true";
import { default as NumberB1QIo3NCPqQPjP_451y3hFd0zIGOFFC45pk_45wobV2PZ0gMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Fields/Number.vue?macro=true";
import { default as CategorywHInS30HMAxtL3wIYS8M3Pdth_x29_Bi1JDaWZD2kA0Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Filter/Category.vue?macro=true";
import { default as indexvUT_45GVBt2CVpUeDLYbqOB9H9RMpGxVJ9ANo1geTrX4gMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Filter/index.vue?macro=true";
import { default as Type5s_soisFM0FaS35v_451orL_457oKNzWCrrdwDcBaNGo2WYMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Filter/Type.vue?macro=true";
import { default as MoreInfoBlockfRe90OGB9sT7_2A5_45T6t9OJa9U1j7FPQDM9_45atS2VQEMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/MoreInfoBlock.vue?macro=true";
import { default as PaymentCard5Hwa2wKkOoFwWS0zkb5WAakwJ9wcY49xlc_457cRkRlDYMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/PaymentCard.vue?macro=true";
import { default as ChartKQWbttfIaR4LsodK9wdBGbHjbkdaH7SioJrsHxM_45yUQMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Chart.vue?macro=true";
import { default as index1VNUj1NleVHjy4WyBUt2vey_45PY7XXYtf8ie8BYC_b48Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Filter/index.vue?macro=true";
import { default as PeriodD633OBJ19lJQmGkof1SJ5mMRDBA3bxITEy942EsRC_45EMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Filter/Period.vue?macro=true";
import { default as index0sL2Oj698FqhRbheuntBdS12hEBixwKj0M7HTtBO_456cMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/index.vue?macro=true";
import { default as indexO10JGK2pOPGenWZqK_45O5PPJ3N8aOdveVxy_DdROPeV4Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/List/index.vue?macro=true";
import { default as ItemRkGlpW3uzmrfqEtFeIGUZASeEma5u9OS1Fj3x4yA7E0Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/List/Item.vue?macro=true";
import { default as TableiRic2m8m6JLA99IbaOfkVCRLVddEH21HMCi_zYjt6pIMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/List/Table.vue?macro=true";
import { default as Modal3XrwNyqoTiVWAJDkWdjfUP6Fx0rJGpig_45ksQTInoC1UMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Modal.vue?macro=true";
import { default as TotalAmountBlockX0VRkMJWrVyp023WobRDkkCddT9y3m6FIoJORzdKfksMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/TotalAmountBlock.vue?macro=true";
import { default as indexxYFn3z_45EO4OCtLArBQiL0cfFwhQ4DCeO0xpHLjonHUMMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/index.vue?macro=true";
import { default as Calendar7ei1Ebe08kD5GXeGcNs1siAZE3IUomGxt7SODXEIeK4Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/Calendar.vue?macro=true";
import { default as CalendarCelllKy9pGp_45CsqYNMWGJcDB5KQ5P__iNQYSqSDrPH29FRYMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/components/CalendarCell.vue?macro=true";
import { default as HistoryS5WHPxmuPLH0_45R_45_vAZTMSahPmVbXLC8FkTT0l_wlLoMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/components/History.vue?macro=true";
import { default as Table8TWPvWJvR7Uz4fDdYNo9sEaKI1HN8MwK2N4N__0mtAgMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/Table.vue?macro=true";
import { default as NavCarduKs2Qo5zYZR5_45aKhIg3SsxSE_45T6now8OjxrluaOz_McMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Home/components/NavCard.vue?macro=true";
import { default as indexHjTqEfB5vtP0oM7ABcYz2FLbYl7cp7btg5nI272wMlcMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/Home/index.vue?macro=true";
import { default as indexU6Wyfu0HrgsRryTRqSB3npP5vVTdeDH9kG4WYwmV3FYMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/NoAccess/index.vue?macro=true";
import { default as indexPrvrvB1Y3XTE_451TDDHCMKTBsdwDYO34ML_Znz1Fuy8EMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/index.vue?macro=true";
import { default as LayouttAa4z8PR5uI3XRGZXTIVfSJKsIMo_MQEuuzw_455OsoNIMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/Layout.vue?macro=true";
import { default as ModeConfirmationPggXwPxUqxKI0n8VWpZhqethPv_45_92b_Hdm_0SE2E7MMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/ModeConfirmation.vue?macro=true";
import { default as ModeShipmentQUJh8i5zwPCmRbn6RP4RLgiveFGRhOHBKCvrOxH_45FAsMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/ModeShipment.vue?macro=true";
import { default as PositionY1EGSsEkg_qvjqGxpHbZphDTp4x_JICsGYWrgbH_459zAMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/Position.vue?macro=true";
import { default as ShippingDateModalXigAAEkDAuBpa7CgoovUac6SH3cjXyVlWvM04HZMEMsMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/ShippingDateModal.vue?macro=true";
import { default as TotalSendUAlBiaw3Q0VII4Rbw6ZijWygqvwgm4PsWbK5xMnx8A4Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/TotalSend.vue?macro=true";
import { default as Footer6jG6HGqt0VjLeyaUeI0Sd9PQGLXdRvZ_45yqq6__7QZvwMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Footer.vue?macro=true";
import { default as HeaderIwg_45zlc6YlXyQJOfP9Ys1GTw8pursxrBHbRkPJ89L4kMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Header.vue?macro=true";
import { default as indexIDguLNIYL6UDsc486ugUnnVBfQ7999JWbgpCd4nLN4UMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/index.vue?macro=true";
import { default as ErrorsModalM1zdtBXHf_45zNmA8gApGzr_fhzyQz9AncR5LK0GyTw20Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ErrorsModal.vue?macro=true";
import { default as ExcelBtnDc4m8WraxBbxFbUwwPI71RC7FRhyg7t6evcxB_4576KAAMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ExcelBtn.vue?macro=true";
import { default as FormsBHrtlfsg_45OZUKTqHPvlNDIoeYENggSm_JrCZ3iCfxYMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Addresses/Form.vue?macro=true";
import { default as indexxowvwBGBcRGGIAZcItQhfT5ATWkZtcsgdVXYx2ahZsgMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Addresses/index.vue?macro=true";
import { default as indexNGLMi476JESLn9Mt7DVGNxOiadkzV_y7xl_459qcrzMzUMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Batch/index.vue?macro=true";
import { default as Block5xqA4njiASdbLECHKCBAvBNOsiIbNal_FLiy1ZiR1P0Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Block.vue?macro=true";
import { default as CardW_ihlyrhun0MtxCGsaTdYYEM7xUkTejLKVif3A5Gp7cMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Card.vue?macro=true";
import { default as Form4JnjE_45KXG0xTx0B4mk2LQwJWyi4TREmBmiOmKGl3zTAMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Contacts/Form.vue?macro=true";
import { default as indexWGyPiQBFTHs_45gP5yH1w54EbkfazidJ74FyvS0MCC4K4Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Contacts/index.vue?macro=true";
import { default as DimensionKw3QOwFkwl38MwWAxOY0P7sC3BXEOR_45RiOZmSkasQfwMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Dimension.vue?macro=true";
import { default as indexFxlW8JpfQFk7DVvQwPfGep2azAw7kEChnN1aLSUMj5MMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/index.vue?macro=true";
import { default as SizeatGXs6CPs1MVf2OH_KL_zsnYTPM88pD7yBHcwUJVpcYMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Size.vue?macro=true";
import { default as SizeswvLQagi6GeFXExC5KgUCZxJe3XQOD8_4549FOcQCWxptQMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Sizes.vue?macro=true";
import { default as FormaQlsLk89rrY9bXvjJ6xM9lmugHTpY4xzXcr0KWxAk8EMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Form.vue?macro=true";
import { default as indexUyjor_YGXD3lkxJqrTKdhAbB8_45e65wbo9P0yLn0Rnq8Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/index.vue?macro=true";
import { default as index0n8R90pvXD2WiQ3_JhSj1IF24PUKvjtU7uZya2On9egMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Positions/index.vue?macro=true";
import { default as ItemkV77bKYFFmYuiZ_cuGRwfKEj3_oYoaoZlibj8DKrc6oMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Positions/Item.vue?macro=true";
import { default as indexdikT0pACpcxFxwOdndEndcMItPegbZGpAtRkvYbOc3sMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/TransportCompany/index.vue?macro=true";
import { default as ShippingTabspQqLhxLoTBidrWCFQh1kdMYfT3XGPBgDvnyM5F_8whkMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingTabs.vue?macro=true";
import { default as StageG2kPhmpHRDYfud7fktovKB6Cu2le1yT9kOGtyta6M_EMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Stage.vue?macro=true";
import { default as errorsValidatepidSE20s6ZgTXSIMewP4EEBDYcm3DxzaNt8bMO6GcIAMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/helpers/errorsValidate.ts?macro=true";
import { default as validateSQ0_452ckLMphN23OknYdKzhdCsB7A_45puQi4kYwqGVGNQMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/helpers/validate.ts?macro=true";
import { default as indexADRp2S4ib7QdsORhTdEAuEt5KgpIm06W3CaxXA12bDsMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/index.vue?macro=true";
import { default as Shipping2HnRLlfgPV2X_45PZBJ8_45p_45GZH5UNBHwopeZqDTe_45beWwMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/Shipping.vue?macro=true";
import { default as indexuO4JV151HBH2RScflNnzuUMjD57HVNwzOT_smXOSGjoMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/index.vue?macro=true";
import { default as LayoutlJ0MG67sEy0AudZcdKYv_45dqro3vD_45nI2PdBpMdw0aQ0Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Layout.vue?macro=true";
import { default as PartgxOGwxAYTGyxWbiPjohjeKMyN9XbhPy8rmVKu52C5LIMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Positions/Part.vue?macro=true";
import { default as PartAnalogToHshJrGYrwVdj8ScdAG_95KzXVYGw2l7b26BxjgpwkMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Positions/PartAnalog.vue?macro=true";
import { default as TechPb6PUZ5xmAy0BPAgfVAmQuRc2j8G6knPvzm9iDozZxYMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Positions/Tech.vue?macro=true";
import { default as TotalSendD2izOXMCnLhZAs2uKD7iMIA_45uy476Plz501mB_45Sp1jkMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/TotalSend.vue?macro=true";
import { default as FooterapI7ANLjjiO_45pwSIRfTS9w4H1OOEbnQ679BoxNsC3eUMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Footer.vue?macro=true";
import { default as Headerr4eeADns2mfjFJj2Rd5LBO1nU_45uWi8dFU0SycLr0KsMMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Header.vue?macro=true";
import { default as indexhLNNVjKfevTgLD3ypoW4AZM_45sbKDLNZrnFGDSNTVWBAMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/index.vue?macro=true";
import { default as ErrorsModalSPofT04_CuH06AB6YQNhWaFE_45FPRyv5KF4QvUl2voy8Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/ErrorsModal.vue?macro=true";
import { default as ExcelBtnsIWbKS1FphsePWseRll2tpktWjIxZS6N2z9P3ZIRYEEMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/ExcelBtn.vue?macro=true";
import { default as StageAzLw_NevZsOgaCX24EOe6gvstxeDT8HWYbTQvjqLsi0Meta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Stage.vue?macro=true";
import { default as validate8ENry0rQ8rvyrkND99W6g9dECK9_457vNxsYJog1ILauoMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/helpers/validate.ts?macro=true";
import { default as indexX7D_HH_Syrpoa_MsfNEs9URVIdFksvC7iDcj0JSnX9QMeta } from "/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/index.vue?macro=true";
export default [
  {
    name: "Auth-AutoLogin",
    path: "/Auth/AutoLogin",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Auth/AutoLogin.vue")
  },
  {
    name: "Auth",
    path: "/Auth",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Auth/index.vue")
  },
  {
    name: "CommercialAgreements-components-FinancingConditions-id",
    path: "/CommercialAgreements/components/FinancingConditions/id",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/FinancingConditions/id.vue")
  },
  {
    name: "CommercialAgreements-components-FinancingConditions",
    path: "/CommercialAgreements/components/FinancingConditions",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/FinancingConditions/index.vue")
  },
  {
    name: "CommercialAgreements-components-Menu",
    path: "/CommercialAgreements/components/Menu",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/Menu.vue")
  },
  {
    name: "CommercialAgreements-components-NegotiationProtocol-components-Filter",
    path: "/CommercialAgreements/components/NegotiationProtocol/components/Filter",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/NegotiationProtocol/components/Filter.vue")
  },
  {
    name: "CommercialAgreements-components-NegotiationProtocol-components-Item",
    path: "/CommercialAgreements/components/NegotiationProtocol/components/Item",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/NegotiationProtocol/components/Item.vue")
  },
  {
    name: "CommercialAgreements-components-NegotiationProtocol",
    path: "/CommercialAgreements/components/NegotiationProtocol",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/NegotiationProtocol/index.vue")
  },
  {
    name: "CommercialAgreements-components-PaymentTerms-components-Filter",
    path: "/CommercialAgreements/components/PaymentTerms/components/Filter",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/PaymentTerms/components/Filter.vue")
  },
  {
    name: "CommercialAgreements-components-PaymentTerms-components-Item",
    path: "/CommercialAgreements/components/PaymentTerms/components/Item",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/PaymentTerms/components/Item.vue")
  },
  {
    name: "CommercialAgreements-components-PaymentTerms",
    path: "/CommercialAgreements/components/PaymentTerms",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/PaymentTerms/index.vue")
  },
  {
    name: "CommercialAgreements-components-ShortLists-components-Excel",
    path: "/CommercialAgreements/components/ShortLists/components/Excel",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/Excel.vue")
  },
  {
    name: "CommercialAgreements-components-ShortLists-components-Filter",
    path: "/CommercialAgreements/components/ShortLists/components/Filter",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/Filter.vue")
  },
  {
    name: "CommercialAgreements-components-ShortLists-components-ItemsTable",
    path: "/CommercialAgreements/components/ShortLists/components/ItemsTable",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/ItemsTable.vue")
  },
  {
    name: "CommercialAgreements-components-ShortLists-components-TypeItem",
    path: "/CommercialAgreements/components/ShortLists/components/TypeItem",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/components/TypeItem.vue")
  },
  {
    name: "CommercialAgreements-components-ShortLists-id",
    path: "/CommercialAgreements/components/ShortLists/id",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/id.vue")
  },
  {
    name: "CommercialAgreements-components-ShortLists",
    path: "/CommercialAgreements/components/ShortLists",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/components/ShortLists/index.vue")
  },
  {
    name: "CommercialAgreements-id",
    path: "/CommercialAgreements/id",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/id.vue")
  },
  {
    name: "CommercialAgreements",
    path: "/CommercialAgreements",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/index.vue")
  },
  {
    name: "CommercialAgreements-layout",
    path: "/CommercialAgreements/layout",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/layout.vue")
  },
  {
    name: "CommercialAgreements-type",
    path: "/CommercialAgreements/type",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/CommercialAgreements/type.vue")
  },
  {
    name: "Financing-Payments-components-Excel",
    path: "/Financing/Payments/components/Excel",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Excel/index.vue")
  },
  {
    name: "Financing-Payments-components-Excel-Modal",
    path: "/Financing/Payments/components/Excel/Modal",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Excel/Modal.vue")
  },
  {
    name: "Financing-Payments-components-Fields-Amount",
    path: "/Financing/Payments/components/Fields/Amount",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Fields/Amount.vue")
  },
  {
    name: "Financing-Payments-components-Fields",
    path: "/Financing/Payments/components/Fields",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Fields/index.vue")
  },
  {
    name: "Financing-Payments-components-Fields-Number",
    path: "/Financing/Payments/components/Fields/Number",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Fields/Number.vue")
  },
  {
    name: "Financing-Payments-components-Filter-Category",
    path: "/Financing/Payments/components/Filter/Category",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Filter/Category.vue")
  },
  {
    name: "Financing-Payments-components-Filter",
    path: "/Financing/Payments/components/Filter",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Filter/index.vue")
  },
  {
    name: "Financing-Payments-components-Filter-Type",
    path: "/Financing/Payments/components/Filter/Type",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Filter/Type.vue")
  },
  {
    name: "Financing-Payments-components-MoreInfoBlock",
    path: "/Financing/Payments/components/MoreInfoBlock",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/MoreInfoBlock.vue")
  },
  {
    name: "Financing-Payments-components-PaymentCard",
    path: "/Financing/Payments/components/PaymentCard",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/PaymentCard.vue")
  },
  {
    name: "Financing-Payments-components-Statistic-Chart",
    path: "/Financing/Payments/components/Statistic/Chart",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Chart.vue")
  },
  {
    name: "Financing-Payments-components-Statistic-Filter",
    path: "/Financing/Payments/components/Statistic/Filter",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Filter/index.vue")
  },
  {
    name: "Financing-Payments-components-Statistic-Filter-Period",
    path: "/Financing/Payments/components/Statistic/Filter/Period",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Filter/Period.vue")
  },
  {
    name: "Financing-Payments-components-Statistic",
    path: "/Financing/Payments/components/Statistic",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/index.vue")
  },
  {
    name: "Financing-Payments-components-Statistic-List",
    path: "/Financing/Payments/components/Statistic/List",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/List/index.vue")
  },
  {
    name: "Financing-Payments-components-Statistic-List-Item",
    path: "/Financing/Payments/components/Statistic/List/Item",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/List/Item.vue")
  },
  {
    name: "Financing-Payments-components-Statistic-List-Table",
    path: "/Financing/Payments/components/Statistic/List/Table",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/List/Table.vue")
  },
  {
    name: "Financing-Payments-components-Statistic-Modal",
    path: "/Financing/Payments/components/Statistic/Modal",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/Statistic/Modal.vue")
  },
  {
    name: "Financing-Payments-components-TotalAmountBlock",
    path: "/Financing/Payments/components/TotalAmountBlock",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/components/TotalAmountBlock.vue")
  },
  {
    name: "Financing-Payments",
    path: "/Financing/Payments",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/index.vue")
  },
  {
    name: "Financing-Payments-view-Calendar",
    path: "/Financing/Payments/view/Calendar",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/Calendar.vue")
  },
  {
    name: "Financing-Payments-view-components-CalendarCell",
    path: "/Financing/Payments/view/components/CalendarCell",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/components/CalendarCell.vue")
  },
  {
    name: "Financing-Payments-view-components-History",
    path: "/Financing/Payments/view/components/History",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/components/History.vue")
  },
  {
    name: "Financing-Payments-view-Table",
    path: "/Financing/Payments/view/Table",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Financing/Payments/view/Table.vue")
  },
  {
    name: "Home-components-NavCard",
    path: "/Home/components/NavCard",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Home/components/NavCard.vue")
  },
  {
    name: "Home",
    path: "/Home",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/Home/index.vue")
  },
  {
    name: "NoAccess",
    path: "/NoAccess",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/NoAccess/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Body",
    path: "/PurchasingLogistics/Orders/components/Cards/Body",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Body-Layout",
    path: "/PurchasingLogistics/Orders/components/Cards/Body/Layout",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/Layout.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Body-ModeConfirmation",
    path: "/PurchasingLogistics/Orders/components/Cards/Body/ModeConfirmation",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/ModeConfirmation.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Body-ModeShipment",
    path: "/PurchasingLogistics/Orders/components/Cards/Body/ModeShipment",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/ModeShipment.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Body-Position",
    path: "/PurchasingLogistics/Orders/components/Cards/Body/Position",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/Position.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Body-ShippingDateModal",
    path: "/PurchasingLogistics/Orders/components/Cards/Body/ShippingDateModal",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/ShippingDateModal.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Body-TotalSend",
    path: "/PurchasingLogistics/Orders/components/Cards/Body/TotalSend",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Body/TotalSend.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Footer",
    path: "/PurchasingLogistics/Orders/components/Cards/Footer",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Footer.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards-Header",
    path: "/PurchasingLogistics/Orders/components/Cards/Header",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/Header.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Cards",
    path: "/PurchasingLogistics/Orders/components/Cards",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Cards/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ErrorsModal",
    path: "/PurchasingLogistics/Orders/components/ErrorsModal",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ErrorsModal.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ExcelBtn",
    path: "/PurchasingLogistics/Orders/components/ExcelBtn",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ExcelBtn.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Addresses-Form",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Addresses/Form",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Addresses/Form.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Addresses",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Addresses",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Addresses/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Batch",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Batch",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Batch/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Block",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Block",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Block.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Card",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Card",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Card.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Contacts-Form",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Contacts/Form",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Contacts/Form.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Contacts",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Contacts",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Contacts/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Dimensions-Dimension",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Dimension",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Dimension.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Dimensions",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Dimensions",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Dimensions-Size",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Size",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Size.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Dimensions-Sizes",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Sizes",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Dimensions/Sizes.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Form",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Form",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Form.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm",
    path: "/PurchasingLogistics/Orders/components/ShippingForm",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Positions",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Positions",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Positions/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-Positions-Item",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/Positions/Item",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/Positions/Item.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingForm-TransportCompany",
    path: "/PurchasingLogistics/Orders/components/ShippingForm/TransportCompany",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingForm/TransportCompany/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-ShippingTabs",
    path: "/PurchasingLogistics/Orders/components/ShippingTabs",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/ShippingTabs.vue")
  },
  {
    name: "PurchasingLogistics-Orders-components-Stage",
    path: "/PurchasingLogistics/Orders/components/Stage",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/components/Stage.vue")
  },
  {
    name: "PurchasingLogistics-Orders-helpers-errorsValidate",
    path: "/PurchasingLogistics/Orders/helpers/errorsValidate",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/helpers/errorsValidate.ts")
  },
  {
    name: "PurchasingLogistics-Orders-helpers-validate",
    path: "/PurchasingLogistics/Orders/helpers/validate",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/helpers/validate.ts")
  },
  {
    name: "PurchasingLogistics-Orders",
    path: "/PurchasingLogistics/Orders",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/index.vue")
  },
  {
    name: "PurchasingLogistics-Orders-Shipping",
    path: "/PurchasingLogistics/Orders/Shipping",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Orders/Shipping.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Body",
    path: "/PurchasingLogistics/Requests/components/Cards/Body",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/index.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Body-Layout",
    path: "/PurchasingLogistics/Requests/components/Cards/Body/Layout",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Layout.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Body-Positions-Part",
    path: "/PurchasingLogistics/Requests/components/Cards/Body/Positions/Part",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Positions/Part.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Body-Positions-PartAnalog",
    path: "/PurchasingLogistics/Requests/components/Cards/Body/Positions/PartAnalog",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Positions/PartAnalog.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Body-Positions-Tech",
    path: "/PurchasingLogistics/Requests/components/Cards/Body/Positions/Tech",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/Positions/Tech.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Body-TotalSend",
    path: "/PurchasingLogistics/Requests/components/Cards/Body/TotalSend",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Body/TotalSend.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Footer",
    path: "/PurchasingLogistics/Requests/components/Cards/Footer",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Footer.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards-Header",
    path: "/PurchasingLogistics/Requests/components/Cards/Header",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/Header.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Cards",
    path: "/PurchasingLogistics/Requests/components/Cards",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Cards/index.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-ErrorsModal",
    path: "/PurchasingLogistics/Requests/components/ErrorsModal",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/ErrorsModal.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-ExcelBtn",
    path: "/PurchasingLogistics/Requests/components/ExcelBtn",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/ExcelBtn.vue")
  },
  {
    name: "PurchasingLogistics-Requests-components-Stage",
    path: "/PurchasingLogistics/Requests/components/Stage",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/components/Stage.vue")
  },
  {
    name: "PurchasingLogistics-Requests-helpers-validate",
    path: "/PurchasingLogistics/Requests/helpers/validate",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/helpers/validate.ts")
  },
  {
    name: "PurchasingLogistics-Requests",
    path: "/PurchasingLogistics/Requests",
    component: () => import("/var/www/lbr.ru/suppliers/frontend/pages/PurchasingLogistics/Requests/index.vue")
  }
]