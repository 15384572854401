import { defineAsyncComponent } from 'vue'
export default {
  "default-components-footer-components-social-icon": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Footer/components/SocialIcon.vue").then(m => m.default || m)),
  "default-components-footer": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Footer/index.vue").then(m => m.default || m)),
  "default-components-header-components-language-switcher": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/LanguageSwitcher.vue").then(m => m.default || m)),
  "default-components-header-components-login": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/Login.vue").then(m => m.default || m)),
  "default-components-header-components-logo": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/Logo.vue").then(m => m.default || m)),
  "default-components-header-components-logout": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/Logout.vue").then(m => m.default || m)),
  "default-components-header-components-menu": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/Menu.vue").then(m => m.default || m)),
  "default-components-header-components-menu-item": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/MenuItem.vue").then(m => m.default || m)),
  "default-components-header-components-notification": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/Notification.vue").then(m => m.default || m)),
  "default-components-header-components-user-info": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/components/UserInfo.vue").then(m => m.default || m)),
  "default-components-header": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/Header/index.vue").then(m => m.default || m)),
  "default-components-scroll-top": defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/components/ScrollTop.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/var/www/lbr.ru/suppliers/frontend/layouts/default/index.vue").then(m => m.default || m))
}