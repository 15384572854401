import { ELanguage } from '@ts/enums/app.enum';
import LocalStorage from '@h/local-storage';

const defaultLocale: string = import.meta.env.VITE_LANGUAGE || ELanguage.EN;
const languagesAssociation: Record<string, string> = {
    ru: 'ru',
    'ru-RU': 'ru',
    be: 'ru',
    'be-BY': 'ru',
    pl: 'ru',
    'pl-PL': 'ru',
};

const getStartingLocale = (): string => {
    if (process.client) {
        // Берём локализацию из localStorage
        if (LocalStorage.get('locale')) {
            return LocalStorage.get('locale');
        }

        // Берём локализацию из браузера
        const localeBrowser: string =
            navigator && navigator.language ? languagesAssociation[navigator.language] || defaultLocale : defaultLocale;

        LocalStorage.set('locale', localeBrowser);
        return localeBrowser;
    }

    return defaultLocale;
};

export { getStartingLocale };
