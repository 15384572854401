export enum PagesNamesList {
    IN_DEVELOPING = 'inDeveloping',
    HOME = 'home',
    AUTH = 'auth',
    AUTO_LOGIN = 'autoLogin',
    /** Purchasing Logistics **/
    PURCHASING_LOGISTICS_REQUESTS = 'purchasingLogisticsRequests',
    PURCHASING_LOGISTICS_ORDERS = 'purchasingLogisticsOrders',
    PURCHASING_LOGISTICS_ORDERS_SHIPPING = 'purchasingLogisticsOrdersShipping',
    /** Financing **/
    FINANCING_PAYMENTS = 'financingPayments',
    /** Commercial Agreements **/
    COMMERCIAL_AGREEMENTS = 'commercialAgreements',
    COMMERCIAL_AGREEMENTS_TYPE = 'commercialAgreementsType',
    COMMERCIAL_AGREEMENTS_ITEM = 'commercialAgreementsItem',
}

export enum EText {
    TEXT = 'text',
    EMAIL = 'email',
    PASSWORD = 'password',
}

export enum ENumber {
    INTEGER = 'integer',
    DECIMAL = 'decimal',
}

export enum ELanguage {
    RU = 'ru',
    EN = 'en',
}

export enum ESize {
    EXTRA_SMALL = 'extra-small',
    SMALL = 'small',
    DEFAULT = 'default',
    LARGE = 'large',
}

export enum EBtnType {
    PRIMARY = 'primary',
    MORE = 'more',
    LITE = 'lite',
    LINK = 'link',
    OUTLINE = 'outline',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
}

export enum EProductType {
    PARTS = 'parts',
    TECHS = 'techs',
}

export enum ETimelineStateType {
    WARNING = 'warning',
    DEFAULT = 'default',
}

export enum EBadgeType {
    STAGE = 'stage',
    STATUS = 'status',
}

export enum EBadgeLevel {
    INFO = 'info',
    WARNING = 'warning',
}

export enum ESeverityType {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
}

export enum EMediaType {
    IMAGE = 'image',
    VIDEO = 'video',
}

export enum ESourceType {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
}

export enum EPaymentCategory {
    PAID = 'paid',
    PAYABLE = 'payable',
    PLANNED = 'planned',
    REFUND = 'refund',
    PREPAYMENT = 'prepayment',
    DEFERRED = 'deferred',
}

export enum EPaymentType {
    PREPAYMENT = 'prepayment',
    DEFERRED = 'deferred',
}

export enum EOrderMode {
    CONFIRMATION = 'confirmation',
    SHIPMENT = 'shipment',
}

export enum EPeriodTypes {
    MONTH = 'month',
    RANGE = 'range',
}
