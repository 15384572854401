import type { ITokenForm, IToken } from '@ts/interfaces/app.interfaces';
import type { IAuthResponse, IResponse } from '@ts/interfaces/api.interfaces';
import type { NumberOrNull, StringOrNull } from '@ts/types/app.type';
import localStorage from '@h/local-storage';
import api from '@api/auth';

type State = {
    token: IToken | null;
    refreshToken: IToken | null;
};

export const authStore = defineStore('auth', {
    state: (): State => ({
        token: localStorage.get('token', true),
        refreshToken: localStorage.get('refreshToken', true),
    }),
    getters: {
        // Получить токен
        getToken({ token }): StringOrNull {
            return token?.token || null;
        },
        // Получить время жизни токена
        getExpireAt({ token }): NumberOrNull {
            return token?.expireAt || null;
        },
        // Валидность токена
        isValidExpireAt(): boolean {
            return this.getExpireAt ? this.getExpireAt > Math.ceil(Date.now() / 1000) : false;
        },
        // Получить токен для обновления
        getRefreshToken({ refreshToken }): StringOrNull {
            return refreshToken?.token || null;
        },
        // Получить время жизни токена для обновления
        getRefreshExpireAt({ refreshToken }): NumberOrNull {
            return refreshToken?.expireAt || null;
        },
        // Валидность токена для обновления
        isRefreshExpireAt(): boolean {
            return this.getRefreshExpireAt ? this.getRefreshExpireAt > Math.ceil(Date.now() / 1000) : false;
        },
    },
    actions: {
        // Сохранить токен
        setToken(value: IToken): void {
            this.token = value;
            localStorage.set('token', value, true);
        },
        // Сохранить токен
        removeToken(): void {
            this.token = null;
            localStorage.remove('token');
        },
        // Сохранить refresh токен
        setRefreshToken(value: IToken): void {
            this.refreshToken = value;
            localStorage.set('refreshToken', value, true);
        },
        // Сохранить refresh токен
        removeRefreshToken(): void {
            this.refreshToken = null;
            localStorage.remove('refreshToken');
        },
        // Получить токен с сервера
        async getAuthToken(form: ITokenForm): Promise<IResponse> {
            const { ok, message, data } = (await api.getToken(form)) as IResponse;

            if (ok) {
                const { token, refreshToken } = data as IAuthResponse;
                this.setToken(token);
                this.setRefreshToken(refreshToken);
            }

            return { ok, message, data };
        },
        // Получить refresh токен с сервера
        async updateAuthRefreshToken(): Promise<StringOrNull> {
            const { ok, data } = (await api.getRefreshToken(this.getRefreshToken)) as IResponse;

            if (ok) {
                const { token, refreshToken } = data as IAuthResponse;
                this.setToken(token);
                this.setRefreshToken(refreshToken);

                return token.token;
            }

            return null;
        },
        async replaceQsiToJwt(qsiToken: string): Promise<IResponse> {
            const { ok, message, data } = (await api.replaceQsiToJwt(qsiToken)) as IResponse;

            if (ok) {
                const { token, refreshToken } = data as IAuthResponse;
                this.setToken(token);
                this.setRefreshToken(refreshToken);
            }

            return { ok, message, data };
        },
        // Проверка актуальности токенов
        async checkAuthAndGetToken(): Promise<StringOrNull> {
            // Если нет refresh токена и его срока жизни
            if (!this.getRefreshToken || !this.getRefreshExpireAt) {
                return null;
            }

            // Если срок жизни refresh токена истёк, то очищаем localStorage
            if (!(this.getRefreshExpireAt ? this.getRefreshExpireAt > Math.ceil(Date.now() / 1000) : false)) {
                this.removeToken();
                this.removeRefreshToken();
                return null;
            }

            // Если нет токена и его срока жизни
            if (!this.getToken || !this.getExpireAt) {
                return null;
            }

            // Если срок жизни токена истёк, то запрашиваем новый токен
            if (!(this.getExpireAt ? this.getExpireAt > Math.ceil(Date.now() / 1000) : false)) {
                return await this.updateAuthRefreshToken();
            }

            return this.getToken;
        },
    },
});
